import { fetchJSON } from '@bonnet/next/fetch';

import { createAction, objectDuckCreator } from '@atc/modular-redux';

import processCrawlPathFlp from '@/utilities/processCrawlPathFlp';

import { flpDuckPreset } from '@/ducks/presets';

import flpCitiesCrawlPathsDuck from './flpCitiesCrawlPathsDuck';

const flpContentDuck = objectDuckCreator({
    ...flpDuckPreset,
    store: 'content',
}).extend({
    types: [
        'SET_CONTENT',
    ],
    creators: ({ types }) => ({
        clearCrawlPaths: () => async (dispatch) => {
            dispatch(flpCitiesCrawlPathsDuck.creators.clear());
        },
        setContent: createAction(types.SET_CONTENT),
        fetchCrawlPaths: ({
            brand = 'ford',
            query,
            isNational,
            channel,
        }) => async (dispatch) => {
            const crawlPathsRequestOptions = {
                circuitBreaker: {
                    id: 'lsc_crawlpath',
                    timeout: 3000,
                    resetTimeout: 300000,
                    fallback: {},
                },
                query: { ...query, channel },
                headers: {
                    'X-fwd-svc': 'ford',
                },
            };

            const nationalQuery = {
                ...crawlPathsRequestOptions?.query,
            };
            if (isNational) {
                delete nationalQuery.city;
                delete nationalQuery.state;
                delete nationalQuery.zip;
                delete nationalQuery.location;
                delete nationalQuery.searchRadius;
            }

            const nationalCrawlPathOptions = {
                ...crawlPathsRequestOptions,
                query: nationalQuery,
            };

            const fetches = {};

            const citiesOptions = isNational ? nationalCrawlPathOptions : crawlPathsRequestOptions;
            fetches.citiesCrawlPaths = fetchJSON('/rest/lsc/crawl/cities', citiesOptions);

            const fetchResults = await Promise.all(Object.values(fetches));

            const pageData = fetchResults.reduce((acc, data, index) => {
                const key = Object.keys(fetches)[index];
                acc[key] = data;
                return acc;
            }, {});

            await processCrawlPathFlp({
                brand,
                data: pageData,
                dispatch,
                query,
            });
        },
    }),
});

export default flpContentDuck;
