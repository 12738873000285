import { objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'srpDealershipsCrawlPaths',
    initialState: {
        dealershipsData: {},
    },
});
