import _get from 'lodash/get';

import { translateKeys } from '@atc/bonnet-parameters';

import {
    flpCitiesCrawlPathsDuck,
} from '@/ducks/flp';

import { getPathSRP } from '.';

// update a collection of data with  a CS formatted path
// using a supplied query and dynamic parameter value
const updateWithPath = async ({ data = [], query = {}, brand }) => {
    const buildPromises = data.map(async (item) => {
        if (item.codes) {
            if (item.codes.startYear && item.codes.endYear) {
                item.codes.startYear = item.codes.startYear[0];
                item.codes.endYear = item.codes.endYear[0];
            }
        }

        const queryCopy = { ...query, city: item.city, state: item.state };
        if (queryCopy.zip) {
            delete queryCopy.zip;
        }

        const path = await getPathSRP({
            ...translateKeys(queryCopy, { target: 'lsc' }),
            ...item.codes,
        }, {
            basePath: true,
            brand,
        });

        return {
            ...item,
            link: path,
        };
    });

    return Promise.all(buildPromises);
};

const processCrawlPathData = async ({ duck, dispatch, dataPath, storeKey, query, lists, pageData }) => {
    const data = _get(pageData, dataPath, false);

    if (data) {
        // update the data with paths built by bonnet-paths
        const updatePromises = lists.map(async (key) => ({ [key]: await updateWithPath({ data: data[key], query, brand: 'ford' }) }));
        const updatedLists = await Promise.all(updatePromises);

        // update the data set with the updated lists with paths
        const crawlPathData = {
            ...data,
            ...updatedLists.reduce((acc, list) => ({ ...acc, ...list }), {}),
        };
        // add the data to a batch action to be dispatched later
        return dispatch(duck.creators.setKey(storeKey, crawlPathData));
    }

    return dispatch(duck.creators.reset());
};

export default async function processCrawlPathFlp({
    data = {},
    brand = 'ford',
    dispatch = () => {},
    query = {},

}) {

    const queryList = {
        city: query.city,
        state: query.state,
        zip: query.zip,
        startYear: query.startYear,
        endYear: query.endYear,
        fuelTypeGroup: query.fuelTypeGroup,
    };
    const sharedCrawlPathData = {
        brand,
        dispatch,
        pageData: data,
        query: queryList,
    };

    const cityPath = 'citiesCrawlPaths';
    const cityList = ['links'];

    // Process cities crawlpath for canonical and non-canonical pages
    await processCrawlPathData({
        ...sharedCrawlPathData,
        duck: flpCitiesCrawlPathsDuck,
        dataPath: cityPath,
        storeKey: 'citiesData',
        lists: cityList,
        query: queryList,
    });
}
