import _get from 'lodash/get';

export function getDealerDetailUrl(owner, ddpBasePath = '/car-dealers') {
    const { state, city } = _get(owner, 'location.address', {});
    const ownerId = owner?.id || owner?.ownerId;
    const keepTrailingSlash = ddpBasePath !== '/car-dealers';

    if (state && city && ownerId) {
        const sanitizePattern = /[^A-Za-z0-9\s]/gi;
        const sanitizeString = (str) => str.replace(sanitizePattern, '').replace(/[\s-]+/g, '-');
        // Removes spaces and special characters from city name and owner names
        const cityName = sanitizeString(city);
        const urlOwnerName = owner.name.replace(sanitizePattern, ' ').replace(/\s\s+/g, ' ').trim().split(' ')
            .join('-');

        const href = `${ddpBasePath}/${cityName}-${state}/${ownerId}/${urlOwnerName}${keepTrailingSlash ? '/' : ''}`;
        return {
            href: href.toLowerCase(),
        };
    }
    return null;
}

export default getDealerDetailUrl;
